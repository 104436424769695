import { provide, ref } from 'vue';
import { notificationEmitter, showNotificationKey } from './notification-emitter';

export interface NotificationData { message: string, type: 'success' | 'error', duration?: number }

export type ShowNotification = (data: NotificationData) => void;
export const showNotificationInjectKey = Symbol('show-notification');

interface NotificationManagerParmas {
  duration: number
}

const defaultNotificationDuration = 1500;

export function useNotificationManager(params?: NotificationManagerParmas) {
  const notificationAnimationDuration = params?.duration || 500;

  const isNotificationOpened = ref(false);
  const notificationsPool = ref<NotificationData[]>([]);
  const currentNotification = ref<NotificationData | null>(null);

  function closeNotification(duration: number) {
    setTimeout(() => {
      isNotificationOpened.value = false;
      currentNotification.value = null;

      setTimeout(() => {
        showNextNotification();
      }, notificationAnimationDuration);
    }, duration + (notificationAnimationDuration));
  }

  function showNextNotification() {
    if (notificationsPool.value.length) {
      currentNotification.value = notificationsPool.value.pop();
      console.log(currentNotification.value);
      isNotificationOpened.value = true;

      closeNotification(currentNotification.value.duration || defaultNotificationDuration);
    }
  }

  function showNotification(data: NotificationData) {
    if (!isNotificationOpened.value) {
      currentNotification.value = data;
      isNotificationOpened.value = true;

      closeNotification(data.duration || defaultNotificationDuration);

      return;
    }

    notificationsPool.value.push(data);
  }

  notificationEmitter.on(showNotificationKey, showNotification);

  provide<ShowNotification>(showNotificationInjectKey, showNotification);

  return { isNotificationOpened, showNotification, notificationAnimationDuration, currentNotification };
}
